<script>
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import { useNotification } from '@/composables/useNotification';

export default defineComponent({
  name: 'LoginForm',

  components: {},

  emits: [],

  setup() {
    const { login } = useAuthStore();
    const { push } = useRouter()
    const { error } = useNotification();

    const form = reactive({
      data: {
        email: '',
        password: '',
      },
      pending: false,
      errors: null,
    })

    async function onSubmit() {
      form.pending = true;

      try {
        await login(form.data);
        push({ name: 'dashboard-users' });
      } catch (errors) {
        form.errors = errors;
        error(errors?.response?.data?.message ?? errors);
      }

      form.pending = false;
    }

    return {
      form,
      onSubmit,
    }
  },
});
</script>

<template>
  <div class="card">
    <form
      class="card-content"
      @submit.prevent="onSubmit"
    >
      <o-field>
        <o-input
          placeholder="Email"
          icon="account"
          v-model="form.data.email"
        />
      </o-field>

      <o-field>
        <o-input
          type="password"
          placeholder="Пароль"
          v-model="form.data.password"
          icon="lock"
        />
      </o-field>

      <o-button
        variant="info"
        expanded
        :loading="form.pending"
        nativeType="submit"
      >
        Войти
      </o-button>
    </form>
  </div>
</template>

<style scoped>
.card {
  width: 100%;
}
</style>
