<script>
import { defineComponent, onMounted } from 'vue';
import { useAxios } from '@/composables/useAxios';

export default defineComponent({
  name: 'App',

  setup() {
    const $axios = useAxios();

    onMounted(() => {
      $axios.get('sanctum/csrf-cookie')
    })
  },
});
</script>

<template>
  <div>
    <RouterView />
  </div>
</template>
