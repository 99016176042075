import axios from 'axios';
import Cookies from 'js-cookie';

export const useAxios = (options, isNotBearer) => {
  let defaults = {
    baseURL: import.meta.env.VITE_BASE_URL_API,
    withCredentials: true,
  }

  if (!isNotBearer) {
    const cookie = Cookies.get('token_session')

    defaults = {
      ...defaults,
      headers: {
        Authorization:  `Bearer ${ cookie }`,
      }
    }
  }

  return axios.create({
    ...defaults,
    ...options
  })
}
