import { useProgrammatic } from '@oruga-ui/oruga-next';

export const useNotification = (duration = 5000, position = 'bottom-right', closable = true) => {
  const { oruga } = useProgrammatic();

  const error = (message) => {
    oruga.notification.open({
      duration,
      message: message,
      position,
      variant: 'danger',
      closable,
    });
  };

  const success = (message) => {
    oruga.notification.open({
      duration,
      message: message,
      position,
      variant: 'success',
      closable,
    });
  };

  return {
    error,
    success,
  };
}
