import Cookies from 'js-cookie';

export function checkAuthMiddleware(to) {
  const isAuthorized = !!Cookies.get('token_session');


  if (!isAuthorized && to.name !== 'login') return { name: 'login' };
  if (isAuthorized && to.name === 'login') return { name: 'dashboard-users' };

  return true;
}
