import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import { checkAuthMiddleware } from '@/router/middlewares/checkAuthMiddleware';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/dashboard/users/:id/edit',
      name: 'dashboard-users-edit',
      props: true,
      component: () => import('@/views/UserView.vue'),
    },
    {
      path: '/dashboard/users',
      name: 'dashboard-users',
      component: () => import('@/views/UsersView.vue'),
    },
    {
      path: '/dashboard/football',
      name: 'dashboard-football',
      component: () => import('@/views/FootballView.vue'),
    },
    {
      path: '/dashboard/cs',
      name: 'dashboard-cs',
      component: () => import('@/views/CsView.vue'),
    },
    {
      path: '/dashboard/dota',
      name: 'dashboard-dota',
      component: () => import('@/views/DotaView.vue'),
    },
    {
      path: '/dashboard/league-of-legends',
      name: 'dashboard-league-of-legends',
      component: () => import('@/views/LolView.vue'),
    },
    {
      path: '/dashboard/forecast-schedule',
      name: 'dashboard-forecast-schedule',
      component: () => import('@/views/ForecastScheduleView.vue'),
    },
    {
      path: '/dashboard/add-forecast-group',
      name: 'dashboard-add-forecast-group',
      component: () => import('@/views/ForecastGroupView.vue'),
    },
    {
      path: '/dashboard/tournaments',
      name: 'dashboard-tournaments',
      component: () => import('@/views/TournamentsView.vue'),
    },
    {
      path: '/dashboard/mail',
      name: 'dashboard-mail',
      component: () => import('@/views/MailView.vue'),
    },
  ],
});

router.beforeEach(checkAuthMiddleware);

export default router;
