<script>
import { defineComponent, ref } from 'vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import LoginForm from '@/components/LoginForm.vue';

export default defineComponent({
  name: 'LoginView',

  components: { AuthLayout, LoginForm },

  setup() {
    const componentName = ref('LoginView');

    return {
      componentName,
    };
  },
});
</script>

<template>
  <AuthLayout>
    <LoginForm />
  </AuthLayout>
</template>

<style scoped>
/* ... */
</style>
