<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'AuthLayout',

  components: {},

  setup() {
    const componentName = ref('AuthLayout');

    return {
      componentName,
    };
  },
});
</script>

<template>
  <div class="container">
    <div class="columns is-centered is-align-items-center">
      <div class="column is-4 is-flex is-align-items-center">
        <slot />
      </div>
    </div>
    <!-- /.columns -->
  </div>
</template>

<style scoped>
.container {
  min-height: 100vh;
}

.columns {
  min-height: inherit;
}
</style>
