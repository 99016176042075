import { ref } from 'vue';
import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { useAxios } from '../composables/useAxios';

export const useAuthStore = defineStore('auth', () => {
  const user = ref();
  const $axios = useAxios();

  async function login(data) {
    await $axios.post('/api/auth/login', data)
      .then(({ data }) => {
        Cookies.set('token_session', data.token, {
          expires: 365,
        });

        user.value = data.user;
      })
  }

  return {
    user,
    login,
  };
});
